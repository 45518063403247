<template>
  <div class="finance-page">
    <ParkSelection @getPark="getParkIndex"></ParkSelection>
    <div class="finance-page-title">收款</div>
    <div class="rent-box">
      <div
        class="rent-border"
        v-for="(item, index) in billList"
        :key="index"
        v-loading="loading"
      >
        <div class="title">{{ item.billName }}</div>

        <div class="rent-detail-border" v-if="item.billData.length !== 0">
          <div class="rent-num">{{ item.billTotal }}</div>
          <div class="rent-line"></div>
          <div
            class="rent-list"
            v-for="(item_bill, index) in item.billData"
            :key="index"
          >
            <div class="rent_title" v-if="item_bill.status == 0">全部</div>
            <div class="rent_title" v-else-if="item_bill.status == 1">
              待付款
            </div>
            <div class="rent_title" v-else-if="item_bill.status == 2">
              已逾期
            </div>
            <div class="rent_title" v-else-if="item_bill.status == 3">
              已收款
            </div>
            <div class="rent_title" v-else-if="item_bill.status == 4">
              已结清
            </div>
            <div class="rent_title" v-else-if="item_bill.status == 6">
              已付款
            </div>
            <div class="rent_num">{{ item_bill.count }}</div>
          </div>
        </div>
        <div v-else>
          <el-empty :image-size="150" decription="暂无数据"></el-empty>
        </div>
        <img src="@/assets/images/bms/financial_1.png" alt="" />
      </div>
    </div>

    <div class="finance-page-title">付款</div>
    <div class="pay-sum" v-loading="loading">
      <div class="title">本月往款</div>
      <div class="pen-num">{{ monthPay }} <span>笔</span></div>
      <img src="@/assets/images/bms/financialSumOff.png" alt="" />
    </div>
    <div class="finance-page-title">单据</div>
    <div class="attest">
      <div class="pay-sum" v-loading="loading">
        <div class="title">待生成</div>
        <div class="pen-num">{{ getGenNumTreat }}</div>
        <img src="@/assets/images/bms/financialAttestOff.png" alt="" />
      </div>
      <div class="pay-sum" v-loading="loading">
        <div class="title">已生成</div>
        <div class="pen-num">{{ gotGenNum }}</div>
        <img src="@/assets/images/bms/financialAttestOff.png" alt="" />
      </div>
    </div>

    <QuickLink :linkList="linkData"> </QuickLink>
  </div>
</template>

<script>
import QuickLink from "../../components/QuickLink/index.vue";
import ParkSelection from "../../components/ParkSelection/index.vue";
import {
  getLeaseBill,
  gotGenerated,
  getAdvance,
  getGenerat,
  getTemplateId,
} from "@/api/ruge/bms/customerService/financialStatis.js";
export default {
  components: {
    QuickLink,
    ParkSelection,
  },
  data() {
    return {
      parkList: [],
      parkIndex: 0,
      loading: false,
      linkData: [
        {
          title: "Rspace 收款单",
          iconPathOff: require("@/assets/images/bms/link_rece.png"),
          iconPathOn: require("@/assets/images/bms/link_rece_wh.png"),
          urlType: true,
          linkUrl: "/rspace/#/receipt",
          developUrl: "/rspace/#/receipt",
          sid: "",
        },
        {
          title: "Rspace 凭证",
          iconPathOff: require("@/assets/images/bms/link_voucher.png"),
          iconPathOn: require("@/assets/images/bms/link_voucher_wh.png"),
          urlType: true,
          linkUrl: "/rspace/#/voucher",
          developUrl: "/rspace/#/voucher",
          sid: "",
        },
        {
          title: "Rspace 发票",
          iconPathOff: require("@/assets/images/bms/link_bill.png"),
          iconPathOn: require("@/assets/images/bms/link_bill_wh.png"),
          urlType: true,
          linkUrl: "/rspace/#/invoice",
          developUrl: "/rspace/#/invoice",
          sid: "",
        },
        {
          title: "Rspace 托收",
          iconPathOff: require("@/assets/images/bms/link_coll.png"),
          iconPathOn: require("@/assets/images/bms/link_coll_wh.png"),
          urlType: true,
          linkUrl: "/rspace/#/collection",
          developUrl: "/rspace/#/collection",
          sid: "",
        },
      ],
      financialDataList: [],

      paramData: {
        buildingIds: [],
        floors: [],
        roomIds: [],
        startTime: "",
        endTime: "",
        tenantName: "",
        status: 1,
        receivedBeginTime: 0,
        receivedEndTime: 0,
        classify: 1,
        leaseCode: "",
        storeId: "",
        page: {
          limit: 50,
          offset: 0,
          pageNum: 1,
          sort: [],
        },
        isShow: false,
        billTypes: [],
      },
      billList: [
        {
          billName: "租赁账单",
          billData: [],
          billTotal: "",
        },
        {
          billName: "物业账单",
          billData: [],
          billTotal: "",
        },
      ],
      monthPay: 0,
      gotGenNum: 0, //已生成
      getGenNumTreat: 0, //待生成
      genParam: {
        //已生成
        _t: "",
        building_id: "",
        room_id: "",
        floor: "",
        store_id: "",
        keyword: "",
        page: "",
        limit: "",
        status: "0",
        begin_time: "",
        end_time: "",
      },
      genTreatParam: {
        //待生成
        _t: "",
        building_id: "",
        room_id: "",
        floor: "",
        store_id: "",
        keyword: "",
        fetch_time: "",
        template_id: "",
        page: "",
        limit: "",
        status: "1",
      },
    };
  },
  created() {
    this.getDateStamp();
  },
  methods: {
    getDateStamp() {
      //获取当前月份的第一天第一秒，以及当月的最后一天的最后一秒
      var y = new Date().getFullYear();
      var m = new Date().getMonth() + 1;
      var d = "01";
      m = m < 10 ? "0" + m : m;
      let st_time = [y, m, d].join("-") + " " + "00:00:00";
      let time_1 = new Date(st_time).getTime() / 1000;

      var y_last = new Date().getFullYear();
      var m_last = new Date().getMonth() + 1;
      var d_last = new Date(y_last, m_last, 0).getDate();
      m_last = m_last < 10 ? "0" + m_last : m_last;
      d_last = d_last < 10 ? "0" + d_last : d_last;
      let la_time = [y_last, m_last, d_last].join("-") + " " + "23:59:59";
      let time_2 = new Date(la_time).getTime() / 1000;
      this.paramData.startTime = time_1;
      this.paramData.endTime = time_2;
      this.genParam.begin_time = time_1;
      this.genParam.end_time = time_2;
    },
    getParkIndex(index, projectId) {
      console.log("切换", index, projectId);
      this.paramData.storeId = projectId;
      this.linkData.forEach((item) => {
        item.sid = projectId;
      });
      console.log("this.linkData", this.linkData);
      this.getData();
    },
    getData() {
      this.loading = true;
      this.paramData.classify = 1; //classify区分收款账单类型
      getLeaseBill(this.paramData)
        .then((res) => {
          //租赁账单
          if (res) {
            this.billList[0].billTotal = "";
            this.billList[0].billData = [];
            res.data.total_and_amount.statusCount.forEach((item) => {
              if (item.status == 0) {
                this.billList[0].billTotal = item.count;
              } else {
                this.billList[0].billData.push(item);
              }
            });
          }
        })
        .catch(() => {
          this.billList[0].billTotal = "";
          this.billList[0].billData = [];
        })
        .finally(() => {
          this.loading = false;
        });
      this.paramData.classify = 2; //classify区分收款账单类型
      getLeaseBill(this.paramData)
        .then((res) => {
          //物业账单
          if (res) {
            this.billList[1].billTotal = "";
            this.billList[1].billData = [];
            res.data.total_and_amount.statusCount.forEach((item) => {
              if (item.status == 0) {
                this.billList[1].billTotal = item.count;
              } else {
                this.billList[1].billData.push(item);
              }
            });
          }
        })
        .catch(() => {
          this.billList[1].billTotal = "";
          this.billList[1].billData = [];
        })
        .finally(() => {
          this.loading = false;
        });
      let store_id = this.paramData.storeId;
      this.genParam.store_id = this.paramData.storeId;
      this.genTreatParam.store_id = this.paramData.storeId;

      getAdvance({
        store_id: store_id,
        page: "",
        limit: "",
        type: "",
        _t: "",
        start: this.paramData.startTime,
        end: this.paramData.endTime,
      })
        .then((res) => {
          //付款
          console.log(res);
          if (res) {
            this.monthPay = res.data.wkTotal;
          }
        })
        .catch(() => {
          this.monthPay = 0;
        })
        .finally(() => {
          this.loading = false;
        });
      this.genParam.status = 0;
      gotGenerated(this.genParam)
        .then((res) => {
          //已生成
          if (res) {
            this.gotGenNum = res.data.total;
          }
          console.log(res, "res");
        })
        .catch(() => {
          this.gotGenNum = 0;
        })
        .finally(() => {
          this.loading = false;
        });
      this.getGenTreatData();
    },
    getGenTreatData() {
      let store_id = this.paramData.storeId;
      getTemplateId({
        store_id: store_id,
        limit: "9999",
        page: "1",
        _t: "",
      })
        .then((res) => {
          if (res) {
            if (res.data.list.length > 0) {
              let temp_id = res.data.list[0].id;
              this.genTreatParam.template_id = temp_id;
              getGenerat({
                store_id: store_id,
                template_id: temp_id,
                fetch_time: this.paramData.startTime,
              }).then((res) => {
                //单据待生成，参数待确定，继续对接后台
                if (res.code == 0) {
                  this.genTreatParam.fetch_time = this.paramData.startTime;
                  gotGenerated(this.genTreatParam).then((res) => {
                    //获取待生成
                    if (res) {
                      this.getGenNumTreat = res.data.total;
                    }
                  });
                }
              });
            } else {
              this.getGenNumTreat = 0;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.getGenNumTreat = 0;
        })
        .finally(() => {
          this.loading = false;
        });

      console.log(this.loading, "this.loading");
    },
    changePark(index) {
      this.parkIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.finance-page {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;

  .park {
    display: flex;
    .park-list {
      min-width: 92px;
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #e7e8eb;
      font-size: 14px;
      font-family: Noto Sans SC-Regular, Noto Sans SC;
      font-weight: 400;
      color: #2f3941;
      text-align: center;
      margin-right: 8px;
      cursor: pointer;
    }
    .park-active {
      color: #1a4cec;
      border: 1px solid #1a4cec;
    }
  }
  .finance-page-title {
    margin: 40px 0px 16px 0px;
    font-size: 20px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: bold;
    color: #000000;
  }
  .rent-box {
    display: flex;
    .rent-border {
      width: 520px;
      height: 336px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ebeef5;
      position: relative;
      margin-right: 16px;
      .title {
        margin: 24px 0px 16px 56px;
        height: 24px;
        font-size: 16px;
        font-family: Noto Sans SC-Bold, Noto Sans SC;
        font-weight: bold;
        color: #3b454c;
        line-height: 24px;
      }
      .rent-num {
        margin-left: 28px;
        margin-bottom: 24px;
        height: 40px;
        font-size: 40px;
        font-family: Noto Sans SC-Bold, Noto Sans SC;
        font-weight: bold;
        color: #3b454c;
        line-height: 40px;
      }
      .rent-line {
        margin-left: 24px;
        width: 470px;
        height: 1px;
        background: #ebeef5;
      }
      .rent-detail-border {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-left: 24px;
        .rent-list {
          width: 149px;
          height: 73px;
          background: #ebeef5;
          border-radius: 2px 2px 2px 2px;
          margin-right: 12px;
          margin-bottom: 12px;
          .rent_title {
            margin-top: 9px;
            margin-left: 12px;
            height: 24px;
            font-size: 14px;
            font-family: Noto Sans SC-Medium, Noto Sans SC;
            font-weight: 500;
            color: #2f3941;
            line-height: 24px;
          }
          .rent_num {
            margin-top: 8px;
            margin-left: 12px;
            font-size: 20px;
            font-family: Noto Sans SC-Bold, Noto Sans SC;
            font-weight: bold;
            color: #3b454c;
            line-height: 20px;
          }
        }
      }
      img {
        position: absolute;
        top: 24px;
        left: 24px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .attest {
    display: flex;
  }
  .pay-sum {
    position: relative;
    margin-right: 16px;
    width: 520px;
    height: 130px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #ebeef5;
    padding-left: 24px;
    padding-top: 16px;
    .title {
      margin-left: 32px;
      margin-bottom: 25px;
      height: 24px;
      font-size: 16px;
      font-family: Noto Sans SC-Medium, Noto Sans SC;
      font-weight: 500;
      color: #2f3941;
      line-height: 24px;
    }
    .pen-num {
      margin-bottom: 25px;
      height: 40px;
      font-size: 40px;
      font-family: Noto Sans SC-Bold, Noto Sans SC;
      font-weight: bold;
      color: #3b454c;
      span {
        font-size: 16px;
      }
    }
    img {
      position: absolute;
      top: 17px;
      left: 24px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>